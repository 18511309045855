import { useEffect } from "react";
import { useNavigate } from "react-router-dom"

const Redirector = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if(localStorage.getItem("jwtToken")){
            navigate("app");
        }
        else{
            navigate("/login")
        }
        
    }, []);
//    return (<p>Loading</p>);

}

export default Redirector;
