import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import fetchHandler from '../scripts/fetchHandler.js';
import unauthorized from '../scripts/unauthorized.js';

let moduleToInfo = {
    "locatie": {name: "locatie", getMethod: "getLocaties", key: "planregelId"},
    "ticketuur": {name: "ticketuur", getMethod: "getTicketuren", key: "planregelId"},
    "cursus": {name: "cursus", getMethod: "getCursussen", key: "aanbodId"} 
}

const Entries = () => {
    const navigate = useNavigate();

    const credentials = JSON.parse(localStorage.getItem("credentials"));
    const jwtToken = localStorage.getItem("jwtToken");

    const [snipes, setSnipes] = useState([]);
    const [entries, setEntries] = useState([]);
    const [moduleName, setModuleName] = useState("locatie");
    const [agenda, setAgenda] = useState([]);


    useEffect(() => {
        refresh()
    }, [moduleName]);

    const fetchSnipes = async () => {
        try {
            const url = "snipes/";
            const options = {
                method: 'GET'
            };

            const response = await fetchHandler(url, options, jwtToken, navigate);
            if (response.error) throw Error(response.error)
            setSnipes(response.filter(res => res.active));
        } catch (error) {

        }
    };

    const refresh = async () => {
        fetchEntries(moduleToInfo[moduleName]);
    }

    const fetchEntries = async (moduleInfo) => {
        try {
            const url = `https://publiek.usc.ru.nl/app/api/v1/?module=${moduleInfo.name}&method=${moduleInfo.getMethod}`;
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    'klantId': credentials.klantId,
                    'token': credentials.rscToken
                })
                // body: `klantId=${credentials.klantId}&token=${credentials.rscToken}`
            };

            const response = await fetch(url, options);
            const jsonData = await response.json();
            if (jsonData.error) throw Error(jsonData.error)
            jsonData.forEach(entry => {
                entry.inschrijvingen = parseInt(entry.inschrijvingen);
                entry.maxInschrijvingen = parseInt(entry.maxInschrijvingen);
            });
            setEntries(jsonData);
        } catch (error) {
            if (error.message === "Ongeldige login") {
                unauthorized(navigate);
            }
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000, // Close the notification after 3 seconds
            });
        }
    };

    const fetchAgenda = async () => {
        try {
            const url = "https://publiek.usc.ru.nl/app/api/v1/?module=agenda&method=getAgenda&lang=nl";
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    'klantId': credentials.klantId,
                    'token': credentials.rscToken
                })
                // body: `klantId=${credentials.klantId}&token=${credentials.rscToken}`
            };

            const response = await fetch(url, options);
            const jsonData = await response.json();
            if (jsonData.error) throw Error(jsonData.error)
            setAgenda(jsonData);
        } catch (error) {
            if (error.message === "Ongeldige login") {
                unauthorized(navigate);
            }
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000, // Close the notification after 3 seconds
            });
        }
    };

    useEffect(() => {
        // setLocations(testLocations);
        fetchSnipes();
        fetchAgenda();

        const intervalId = setInterval(()=>{fetchSnipes(); fetchAgenda();}, 30000);

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    async function deleteSnipe(snipe) {
        const url = "snipes/";
        const options = {
            method: 'DELETE',
            body: JSON.stringify({ key: snipe.key })
        };

        const response = await fetchHandler(url, options, jwtToken, navigate);
        fetchSnipes();
    }

    async function addSnipe(entry) {
        const url = "snipes/";
        const options = {
            method: 'POST',
            body: JSON.stringify({ moduleName: moduleName, key: entry[moduleToInfo[moduleName].key], naam: entry.naam, start: entry.start })
        };

        const response = await fetchHandler(url, options, jwtToken, navigate);
        fetchSnipes();
    }

    async function book(entry) {
        const url = "entries/book";
        const options = {
            method: 'POST',
            body: JSON.stringify({ ...entry, moduleName: moduleName })
        };

        const response = await fetchHandler(url, options, jwtToken, navigate);
        fetchAgenda();
    }

    async function deleteBooking(receipt) {
        const url = "entries/delete";
        const options = {
            method: 'POST',
            body: JSON.stringify({ ...receipt, moduleName: receipt.type })
        };

        const response = await fetchHandler(url, options, jwtToken, navigate);
        fetchAgenda();
    }

    async function logOut(){
        try {
            const url = "https://publiek.usc.ru.nl/app/api/v1/?module=user&method=logOut&lang=nl";
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    'klantId': credentials.klantId,
                    'token': credentials.rscToken
                })
                // body: `klantId=${credentials.klantId}&token=${credentials.rscToken}`
            };

            const response = await fetch(url, options);
            const jsonData = await response.json();
            if (jsonData.error) throw Error(jsonData.error)

            toast.success("Successfully logged out", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000, // Close the notification after 3 seconds
            });
            unauthorized(navigate);
        } catch (error) {
            if (error.message === "Ongeldige login") {
                unauthorized(navigate);
            }
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000, // Close the notification after 3 seconds
            });
        }
        
    }

    return (
        <div>
            <Button onClick={logOut}>Log out</Button>
            <AgendaContainer>
                {agenda.map(receipt => (
                    <Row key={receipt[moduleToInfo[receipt.type].key]}>
                        {receipt.naam} - {new Date(receipt.start * 1000).toLocaleString()}
                        <span>Enrolled</span>
                        <Button onClick={() => deleteBooking(receipt)}>Delete</Button>
                    </Row>
                ))}
            </AgendaContainer>

            <SnipesConatiner>
                {snipes.map(snipe => {
                    let foundEntry = entries.find(entry => entry[moduleToInfo[snipe.moduleName].key] === snipe.key);
                    return (
                        <Row key={snipe.key + snipe.moduleName}>
                            {snipe.naam} - {new Date(snipe.start * 1000).toLocaleString()} - {foundEntry?.inschrijvingen}/{foundEntry?.maxInschrijvingen}
                            <span>{snipe.active ? "Sniping" : snipe.message}</span>
                            <Button onClick={() => deleteSnipe(snipe)}>Delete</Button>
                        </Row>
                    )
                }
                )}
            </SnipesConatiner>

            <Row><Button onClick={()=>setModuleName("locatie")}>Locaties</Button><Button onClick={()=>setModuleName("ticketuur")}>Ticket uren</Button><Button onClick={()=>setModuleName("cursus")}>Cursussen</Button>
            <Button onClick={()=>refresh()}>↺</Button></Row>
            {moduleName}
            
            {entries.map(entry => (
                <Row key={entry[moduleToInfo[moduleName].key]}>
                    {entry.naam} - {new Date(entry.start * 1000 ?? 0).toLocaleString()} - {entry.inschrijvingen}/{entry.maxInschrijvingen}
                    {entry.inschrijvingen >= entry.maxInschrijvingen ? <Button onClick={() => addSnipe(entry)}>Snipe</Button> : <Button onClick={() => book(entry)}>Book</Button>}

                </Row>
            ))}
        </div>
    );
};

const Button = styled.button`
`

const AgendaContainer = styled.div`
border-bottom: 2px solid black
`

const SnipesConatiner = styled.div`
    border-bottom: 2px solid black
`

const Row = ({ children }) => {
    return <RowContainer>{children}</RowContainer>;
};

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;


export default Entries;