// import logo from './logo.svg';
// import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './LoginPage.js';
import Entries from './Entries.js';
import Redirector from './Redirector.js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';

function App() {  
  return (
    <React.Fragment>
    <Router>
      <Routes>
      <Route path="/" element={<Redirector/>} />
        <Route path="/login" element={<LoginPage/>} />
        <Route exact path="/app" element={<Entries/>} />
      </Routes>
      
    </Router>
    <ToastContainer />
    </React.Fragment>
  );
}

export default App;
