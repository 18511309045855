import { toast } from 'react-toastify';
import unauthorized from './unauthorized.js';

const handleResponse = (response, navigate) => {
    if (response.status === 401) {
      // Remove the item from localStorage
      unauthorized(navigate);
      throw new Error('Unauthorized');
    }
    return response;
  };
  
  // Wrapper function for the fetch call with response interceptor
  const fetchHandler = (url, options, jwtToken, navigate) => {
    if (!options['headers']) options['headers'] = {}
    options['headers']["Authorization"]=`Bearer ${jwtToken}`;
    options['headers']['Content-Type']='application/json';
    return fetch("api/"+url, options)
      .then(res=>handleResponse(res, navigate)).then(res=>res.json().then(json=>{
        if(json.error) throw Error(json.error);
        toast.success(json.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000, // Close the notification after 3 seconds
        });
        return json;
        }))
      .catch(error => {
        // Handle error if needed
        console.error(error)
        toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000, // Close the notification after 3 seconds
        });
        return null;
      });
  };

  export default fetchHandler;