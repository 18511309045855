import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const P = styled.p`
    font-size: ${props => props.size || '16px'};
    margin-bottom: ${props => props.marginbottom || '8px'};
    margin-top: ${props => props.margintop || '8px'};
`;

const LoginPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  align-items: center;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputField = styled.input`
  margin-bottom: ${props => props.marginbottom || '12px'};
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
`;

const SubmitButton = styled.button`
  padding: 8px;
  background-color: transparent;
  color: black;
  border: none;
  cursor: pointer;
`;



const Login = () => {
    console.log("login");
    const navigate = useNavigate()

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const data = {};

        for (let [key, value] of formData.entries()) {
            data[key] = value;
        }

        
        // const url = "login/";
        // const options = {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded'
        //     },
        //     body: JSON.stringify({ key: snipe.key })
        // };

        // const response = await fetchHandler(url, options, jwtToken, navigate);
        // if (!response) return;
        // const { jwtToken, credentials } = response;

        // localStorage.setItem("credentials", JSON.stringify(credentials))
        // localStorage.setItem("jwtToken", jwtToken)


        const url = "api/" + "login";
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams(data).toString()
        };

        fetch(url, options)
            .then(response => response.json()).then(response => {
                if (response.error) throw Error(response.error)

                const { jwtToken, credentials } = response;

                localStorage.setItem("credentials", JSON.stringify(credentials))
                localStorage.setItem("jwtToken", jwtToken)

                navigate("/app");
            })
            .catch(error => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000, // Close the notification after 3 seconds
                });
            });
    };

    return (
        <LoginPageContainer>
                <P size="24px" marginbottom="20px">Radboud Sports Centre Sniper</P>
           
            <LoginForm onSubmit={handleSubmit}>
                <InputField type="text" name="username" placeholder="Username" />
                <InputField marginbottom="0px" type="password" name="password" placeholder="Password" />
                <P size = "12px">Your password is never stored!</P>

                <SubmitButton type="submit">Login</SubmitButton>
            </LoginForm>
        </LoginPageContainer>
    );
};

export default Login;